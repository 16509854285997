import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './App.css';

const UserPanel = ({ username, password }) => {
  const [userData, setUserData] = useState(null);
 
  useEffect(() => {
    // Make the first POST request with username and password
    axios.post('https://auth.desertpunch.xyz/getEmail', {
      username,
      password,
    })
    .then(response => {
      // Handle the server response
      setUserData(prevUserData => ({
        ...prevUserData,
        email: response.data.email,
      }));
    })
    .catch(error => {
      console.error('Error during server request:', error);
    });

    // Make the second POST request with username and password
    axios.post('https://auth.desertpunch.xyz/getCredits', {
      username,
      password,
    })
    .then(response => {
      // Handle the server response
      setUserData(prevUserData => ({
        ...prevUserData,
        credits: response.data._count,
        userNickname: response.data._userNickname,
      }));
    })
    .catch(error => {
      console.error('Error during server request:', error);
    });
  }, [username, password]);

  // Function to update family name
  useEffect(() => {
    // Make sure userData is not null and userNickname is available before updating family name
    if (userData && userData.userNickname) {
      axios.post('https://auth.desertpunch.xyz/setFamilyName', {
        username,
        password,
        familyname: userData.userNickname,
      })
      .then(response => {
        // Handle the server response if needed
      })
      .catch(error => {
        console.error('Error updating family name:', error);
      });
    }
  }, [userData, username, password]); // Run this effect whenever userData changes

  // Render user-panel and button-row separately
  const renderUserPanel = () => {
  if (userData && userData.userNickname) {
    return (
      <div className="user-panel">
        <div className="your-prof"><h2>Your Profile</h2></div>
        <p>Username: {username}</p>
        <p>Email: {userData.email}</p>
        <p>Password: {'*'.repeat(password.length)}</p>
        <p>Credits: {userData.credits}</p>
        <p>Family Name: {userData.userNickname}</p>
        {/* Add other user data here */}
      </div>
    );
  } else {
    return <div>Loading...</div>; // Or any loading indicator
  }
};

  const renderButtonRow = () => (
  <>
    <div className="button-row">
      <button className="button" onClick={() => window.location.href = "https://donate.stripe.com/8wMeVb5O13No9Ms9AA"}>Donate</button>
      <button className="button" onClick={() => window.location.href = `https://www.arena-top100.com/index.php?a=in&u=DesertPunch`}>Vote</button>
    </div>
    <div className="button-row">
      <button className="button" onClick={() => window.location.href = "https://wiki.desertpunch.xyz"}>Wiki</button>
      <button className="button" onClick={() => window.location.href = "https://discord.gg/Mpnq79kaEm"}>Discord</button>
      <button className="button" onClick={() => window.location.href = "https://drive.usercontent.google.com/download?id=1-4F8pFw2qYKPI45mAhT3RLQ_UEqRJEOC&export=download&authuser=0"}>Download</button>
    </div>
  </>
);


  return (
    <>
      {/* Render user-panel and button-row separately */}
      {renderUserPanel()}
      {renderButtonRow()}
    </>
  );
};

export default UserPanel;
