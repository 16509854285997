import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './App.css';
import UserPanel from './UserPanel';

let [gUsername, gPassword]='';

const RegistrationForm = ({ onClose }) => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [email, setEmail] = useState('');
  const [registrationSuccess, setRegistrationSuccess] = useState(false);
  const [error, setError] = useState('');

  const handleRegistration = async () => {
    try {
		if (password !== confirmPassword) {
		  setError('Passwords do not match');
		  return;
		}
      // Verifica se l'utente esiste già nel database
      const userExistsResponse = await axios.post('https://auth.desertpunch.xyz/checkUserExists', { username });
      if (userExistsResponse && userExistsResponse.data && userExistsResponse.data.exists) {
        setError('Username already taken');
        return;
      }

      // Verifica se l'email esiste già nel database
      const emailExistsResponse = await axios.post('https://auth.desertpunch.xyz/checkEmailExists', { email });
      if (emailExistsResponse && emailExistsResponse.data && emailExistsResponse.data.exists) {
        setError('Mail already taken');
        return;
      }

	// Validate lowercase username
    if (username !== username.toLowerCase()) {
      setError('Username must be lowercase');
      return;
    }
	// Validate email format
		if (!validateEmail(email)) {
		  setError('Invalid email format');
		  return;
		}  
      // Se l'utente e l'email non esistono, procedi con la registrazione
      const registrationResponse = await axios.post('https://auth.desertpunch.xyz/register', {
        username,
        password,
        email,
      });

      console.log('Risposta dal server:', registrationResponse.data);
      setRegistrationSuccess(true);
      // Chiudi il modale dopo la registrazione (simulata)
      setTimeout(() => {
        setRegistrationSuccess(false);
        onClose();
      }, 2000);
    } catch (error) {
      console.error('Errore durante la registrazione:', error.response && error.response.data);
      // Gestisci l'errore come desiderato
    }
  };

  return (
    <div className="registration-modal">
      <h2>Registration</h2>
      {registrationSuccess ? (
        <div className="registration-success">
          <strong>Thanks for registering, you can now join the server!</strong>
        </div>
      ) : (
        <form>
            <label>
			  Username:
			  <input
				type="text"
				value={username}
				onChange={(e) => setUsername(e.target.value.toLowerCase())} // Convert to lowercase on change
			  />
			</label>
          <label>
            Password(Only letters and numbers):
            <input
			  type="password"
			  value={password}
			  onChange={(e) => {
				const val = e.target.value;
				if (/^[a-zA-Z0-9]*$/.test(val)) { // Only allow letters and numbers
				  setPassword(val);
				}
			  }}
			/>
          </label>
		  <label>
			Confirm Password:
            <input
			  type="password"
			  value={confirmPassword}
			  onChange={(e) => {
				const val = e.target.value;
				if (/^[a-zA-Z0-9]*$/.test(val)) { // Only allow letters and numbers
				  setConfirmPassword(val);
				}
			  }}
			/>
          </label>
          <label>
            Email:
            <input type="email" value={email} onChange={(e) => setEmail(e.target.value)} />
          </label>
          <button type="button" onClick={handleRegistration}>Register</button>

          {error && <div className="registration-error">{error}</div>}
        </form>
      )}
    </div>
  );
};

const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

const LoginForm = ({ onClose, onLoginSuccess }) => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [loginSuccess, setLoginSuccess] = useState(false);
  const [error, setError] = useState('');

  const handleLogin = async () => {
    try {
      const loginResponse = await axios.post('https://auth.desertpunch.xyz/checkLogin', {
        username: username,
        password: password,
      });

      console.log('Risposta dal server:', loginResponse.data);

      if (!loginResponse.data.exists) {
        setError('Wrong username or password');
      } else {
        onLoginSuccess(loginResponse.data.userData);
        setLoginSuccess(true);
		gUsername = username;
		gPassword = password;
		console.log({gUsername});
      }
    } catch (error) {
      console.error('Errore durante il login:', error.response && error.response.data);
      setError('Error during login');
    }
  };

  return (
    <div className="login-modal">
      <h2>Login</h2>
      {loginSuccess ? (
        <div className="login-success">
          <strong>Logged in!</strong>
        </div>
      ) : (
        <form>
          <label>
			  Username:
			  <input
				type="text"
				value={username}
				onChange={(e) => setUsername(e.target.value.toLowerCase())} // Convert to lowercase on change
			  />
			</label>
          <label>
            Password:
            <input
			  type="password"
			  value={password}
			  onChange={(e) => {
				const val = e.target.value;
				if (/^[a-zA-Z0-9]*$/.test(val)) { // Only allow letters and numbers
				  setPassword(val);
				}
			  }}
			/>
          </label>
          <button type="button" onClick={handleLogin}>
            Login
          </button>

          {error && <div className="login-error">{error}</div>}
        </form>
      )}
    </div>
  );
};

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isRegistrationModalOpen: false,
      isLoginModalOpen: false,
      loginSuccess: false,
      userData: {},
    };
  }

  componentDidMount() {
    document.title = "DesertPunch";
  }

  openRegistrationModal = () => {
    this.setState({ isRegistrationModalOpen: true });
  };

  closeRegistrationModal = () => {
    this.setState({ isRegistrationModalOpen: false });
  };

  openLoginModal = () => {
    this.setState({ isLoginModalOpen: true });
  };

  closeLoginModal = () => {
    this.setState({ isLoginModalOpen: false });
  };

  handleLoginSuccess = (userData) => {
	  this.setState({ loginSuccess: true, userData, isLoginModalOpen: false });
	  this.closeLoginModal();
	};

  handleLogout = () => {
    this.setState({ loginSuccess: false });
  };

  render() {
  const { isRegistrationModalOpen, isLoginModalOpen, loginSuccess } = this.state;

  return (
		<div className="App">
		  {isRegistrationModalOpen && <div className="overlay" onClick={this.closeRegistrationModal}></div>}
		  {isLoginModalOpen && <div className="overlay" onClick={this.closeLoginModal}></div>}
		  <img src="https://i.imgur.com/miyGtfa.png" alt="Logo" className="logo" />
		  {loginSuccess ? (
			  <>
				<UserPanel username={gUsername} password={gPassword} />
				<button className="login-button" onClick={this.handleLogout}>Logout</button>
			  </>
			) : (
			  <>
				{isLoginModalOpen && <LoginForm onClose={this.closeLoginModal} onLoginSuccess={this.handleLoginSuccess} />}
				{!loginSuccess && isRegistrationModalOpen && <RegistrationForm onClose={this.closeRegistrationModal} />}
			  </>
			)}
		  <div className="button-container">
			{!loginSuccess && (
			  <div className="button-row">
				<button className="button" onClick={() => window.location.href = "https://wiki.desertpunch.xyz"}>Wiki</button>
				<button className="button" onClick={() => window.location.href = "https://discord.gg/Mpnq79kaEm"}>Discord</button>
				<button className="button" onClick={() => window.location.href = "https://drive.usercontent.google.com/download?id=1-4F8pFw2qYKPI45mAhT3RLQ_UEqRJEOC&export=download&authuser=0"}>Download</button>
				<button className="button" onClick={() => window.location.href = "https://donate.stripe.com/8wMeVb5O13No9Ms9AA"}>Donate</button>
			  </div>
			)}
		  </div>
		  {!loginSuccess && (
        <>
          <button className="login-button" onClick={this.openLoginModal}>Login</button>
          <button className="register-button" onClick={this.openRegistrationModal}>Register</button>
        </>
      )}

		</div>
	  );
	}
}

export default App;